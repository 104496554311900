import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Typography, TextField, Button, MenuItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export default function Testing() {



    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "collection_items";
        const id = localStorage.getItem("nonce");

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }
        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&id=" + id;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data)
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);


    function simulatePriceFetch() {

        const eid = localStorage.getItem('default');
        const a = localStorage.getItem("nonce");

        const payload = {
            endpoint: "v1/role/add",
            eid: eid
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === 1 && data.response && data.response.data) {
                    console.log(data)
                }
            })
            .catch(() => {
                alert("Something went wrong");
            });
    }


    const [categories, setCategories] = useState([]);

    const addCategory = () => {
        setCategories([...categories, { title: "", shortDesc: "", longDesc: "", children: [] }]);
    };

    const addSubcategory = (categoryIndex, subcategoryPath) => {
        const updateCategories = (list, path) => {
            if (path.length === 0) {
                return [...list, { title: "", shortDesc: "", longDesc: "", children: [] }];
            }
            const [currentIndex, ...remainingPath] = path;
            return list.map((item, index) =>
                index === currentIndex
                    ? { ...item, children: updateCategories(item.children, remainingPath) }
                    : item
            );
        };
        setCategories(updateCategories(categories, [categoryIndex, ...subcategoryPath]));
    };

    const updateCategory = (categoryIndex, subcategoryPath, field, value) => {
        const updateCategories = (list, path) => {
            if (path.length === 0) {
                return list.map((item, index) =>
                    index === categoryIndex ? { ...item, [field]: value } : item
                );
            }
            const [currentIndex, ...remainingPath] = path;
            return list.map((item, index) =>
                index === currentIndex
                    ? { ...item, children: updateCategories(item.children, remainingPath) }
                    : item
            );
        };
        setCategories(updateCategories(categories, subcategoryPath));
    };

    const buildCategoryData = (category) => {
        return {
            title: category.title,
            short_description: category.shortDesc,
            long_description: category.longDesc,
            children: category.children.map(buildCategoryData),
        };
    };

    const submitTaxonomy = async () => {
        const eid = localStorage.getItem('default');
        const a = localStorage.getItem("nonce");
        const payload = {
            taxonomy: categories.map(buildCategoryData),
            endpoint: "v1/taxonomy/add",
            eid: eid,
            a: a
        };
        try {
            const response = await fetch("/admin/postData.php", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                const text = await response.text();
                alert("Error: " + text);
                return;
            }
            const result = await response.json();
            console.log("Taxonomy created:", result);
            alert("Success! Check console for details.");
        } catch (err) {
            console.error(err);
            alert("Request failed.");
        }
    };

    const renderCategory = (category, categoryIndex, subcategoryPath = []) => (
        <div key={categoryIndex} style={{ marginLeft: subcategoryPath.length * 30 }}>
            <label>
                Title: <input type="text" value={category.title} onChange={(e) => updateCategory(categoryIndex, subcategoryPath, "title", e.target.value)} />
            </label>
            <label>
                Short Desc: <input type="text" value={category.shortDesc} onChange={(e) => updateCategory(categoryIndex, subcategoryPath, "shortDesc", e.target.value)} />
            </label>
            <label>
                Long Desc: <textarea value={category.longDesc} onChange={(e) => updateCategory(categoryIndex, subcategoryPath, "longDesc", e.target.value)} />
            </label>
            <button type="button" onClick={() => addSubcategory(categoryIndex, subcategoryPath)}>
                Add Subcategory
            </button>
            <div className="subcategories">
                {category.children.map((subcat, subIndex) =>
                    renderCategory(subcat, subIndex, [...subcategoryPath, subIndex])
                )}
            </div>
            <hr />
        </div>
    );

    return (
        <div
            style={{ paddingTop: '300px' }}>
            <h1>Create a New Taxonomy</h1>
            <button onClick={addCategory}>Add Root Category</button>
            <div id="categories" style={{ marginTop: 10 }}>{categories.map((cat, index) => renderCategory(cat, index))}</div>
            <button onClick={submitTaxonomy}>Submit</button>
        </div>
    );

}